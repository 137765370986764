export type FontSizeValue = 'Small (1.0rem)' | 'Medium (1.25rem)' | 'Large (1.50rem)';

export const getFontSize = (value?: FontSizeValue) => {
  switch (value) {
    case 'Small (1.0rem)':
      return 'text-base';
    case 'Medium (1.25rem)':
      return 'text-xl';
    case 'Large (1.50rem)':
      return 'text-2xl';
    default:
      return 'text-2xl';
  }
};
