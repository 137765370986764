import React from 'react';
import { Grid } from '@mui/material';
import { map } from 'lodash/fp';

import type {
  ContentfulComponentDefinition,
} from '#contentful/ContentfulComponentDefinition';
import type { BackgroundDefinition } from '#components/Section/SectionSettings';
import SectionSettings from '#components/Section/SectionSettings';

import type { ColumnDefinition } from '#components/Column';
import Column from '#components/Column';
import type { ImageDefinition } from '#contentful/Image';
import useViewport from '#hooks/useViewport';
import type { ContentfulSectionSpacingDefinition } from '#hooks/useSectionSpacing';
import useSectionSpacing from '#hooks/useSectionSpacing';
import type { ContentfulBorderDefinition } from '#hooks/useBorder';
import type { TextDefinition } from '#contentful/Text';
import Text from '#contentful/Text';

export type TabItemDefinition = TabItemProps
& ContentfulComponentDefinition
& {};

export type TabItem = {
  columns: ColumnDefinition[],
  options?: {
    textTransform: string,
    textAlign: string,
    tabContentBackground: BackgroundDefinition,
    tabItemDefaultIcon: ImageDefinition,
    tabItemActiveIcon: ImageDefinition,
    iconPosition: 'start' | 'end' | 'top' | 'bottom',
    tabItemHoverIcon: ImageDefinition,
    tabItemDefaultBackground: TabColor,
    tabItemDefaultFontColor: TabColor,
    tabItemHoverBackground: TabColor,
    tabItemHoverFontColor: TabColor,
    tabItemActiveBackground: TabColor,
    tabItemActiveFontColor: TabColor,
    tabItemDefaultBorder: ContentfulBorderDefinition,
    tabItemHoverBorder: ContentfulBorderDefinition,
    tabItemActiveBorder: ContentfulBorderDefinition,
  }
  title: string,
  introductionSection?: TextDefinition,
  id: string,
};

export type TabColor = {
  value: string,
};

type TabItemProps = TabItem & {
  minHeight?: number,
  tabStyle?: 'Offset' | 'Even Height'
  spacingSettings?: ContentfulSectionSpacingDefinition,
  borderStyling: boolean
};

const TabPanel = ({
  columns,
  options,
  minHeight,
  tabStyle = 'Offset',
  spacingSettings,
  borderStyling,
  introductionSection,
}: TabItemProps) => {
  const {
    tabContentBackground,
  } = options ?? {};

  const isMobile = useViewport() === 'xs';

  const responsiveSectionSpacing = useSectionSpacing(
    {
      ...spacingSettings,
    },
    {
      mtmd: tabStyle === 'Offset' ? 4 : 0,
      mbmd: tabStyle === 'Offset' ? 4 : 0,
      mtlg: tabStyle === 'Offset' ? 4 : 0,
      mblg: tabStyle === 'Offset' ? 4 : 0,
      mllg: 0,
      mrlg: 0,
      ptmd: 1,
      pbmd: 1,
      plmd: 0,
      prmd: 0,
      ptlg: 2,
      pblg: 2,
      pllg: 2,
      prlg: 2,
      plxl: 8,
      prxl: 8,
      columnSpacinglg: 4,
    },
  );

  const settings:BackgroundDefinition = {
    ...tabContentBackground,
    ...responsiveSectionSpacing,
    borderRadiusTop: borderStyling,
    borderRadiusBottom: borderStyling,
  };

  return (
    <SectionSettings {...settings}>
      <Grid
        container
        flexWrap="nowrap"
        rowSpacing={{ xs: 0, sm: 2, md: 0 }}
        direction="column"
        sx={{
          mb: 1,
          minHeight: isMobile ? undefined : minHeight,
          overflow: 'hidden',
          pt: 3,
        }}
        justifyContent={responsiveSectionSpacing.responsiveJustifyContent}
        alignItems={responsiveSectionSpacing.responsiveAlignItems}
        columnSpacing={responsiveSectionSpacing.responsiveColumnSpacing}
      >
        <Grid>
          {introductionSection && <Text content={introductionSection} />}
        </Grid>
        <Grid className="flex">
          {map((column: ColumnDefinition) => (
            <Column key={column.id} {...column} />
          ), columns)}
        </Grid>
      </Grid>
    </SectionSettings>
  );
};

export default TabPanel;
